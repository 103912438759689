'use client';

import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { type Color, brownDark, brownLight, grayDark, grayLight, orangeDark, orangeLight, redDark, redLight } from './colors';

// @deprecated
export const neutral = {
  50: '#fafaf9',
  100: '#f5f5f4',
  200: '#e7e5e4',
  300: '#d6d3d1',
  400: '#a8a29e',
  500: '#78716c',
  600: '#57534e',
  700: '#44403c',
  800: '#292524',
  900: '#1c1917',
};

// @deprecated
export const brand = {
  50: '#efebe9',
  100: '#d7ccc8',
  200: '#bcaaa4',
  300: '#a1887f',
  400: '#8d6e63',
  500: '#795548',
  600: '#6d4c41',
  700: '#5d4037',
  800: '#4e342e',
  900: '#3e2723',
};

declare module '@mui/material/styles' {
  interface PaletteOptions {
    brand: Color;
    neutral: Color;
  }

  interface Palette {
    brand: Color;
    neutral: Color;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    soft: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    soft: true;
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    soft: true;
    surface: true;
  }
  interface PaperPropsColorOverrides {
    primary: true;
  }
}

const pegaseTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data',
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: 'rgb(109, 91, 79)',
          dark: brownLight[110],
          contrastText: '#fff',
        },
        secondary: {
          main: orangeLight[90],
          contrastText: '#fff',
        },
        background: {
          default: grayLight[10],
          paper: '#fff',
        },
        brand: { ...brownLight },
        neutral: {
          ...grayLight,
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: 'rgb(217, 194, 179)',
          dark: 'rgb(38, 29, 23)',
        },
        secondary: {
          main: orangeDark[90],
        },
        background: {
          default: 'rgb(39, 37, 35)', //rgb(68, 57, 50)
          paper: 'rgb(68, 57, 50)', //rgb(45, 35, 29)
        },
        brand: {
          ...brownDark,
        },
        neutral: {
          ...grayDark,
        },
      },
    },
  },
  typography: {
    fontFamily: 'var(--font-inter)',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 520,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  components: {
    MuiChip: {
      variants: [
        {
          props: { variant: 'soft' },
          style: ({ theme }) => ({
            color: neutral[700],
            backgroundColor: neutral[100],
            ...theme.applyStyles('dark', {
              color: neutral[200],
              backgroundColor: neutral[800],
            }),
          }),
        },
        {
          props: { variant: 'soft', color: 'primary' },
          style: ({ theme }) => ({
            color: brand[700],
            backgroundColor: brownLight[30],
            ...theme.applyStyles('dark', {
              color: brand[200],
              backgroundColor: brownDark[30],
            }),
          }),
        },
        {
          props: { variant: 'soft', color: 'secondary' },
          style: ({ theme }) => ({
            color: brand[700],
            backgroundColor: brand[100],
            ...theme.applyStyles('dark', {
              color: brand[200],
              backgroundColor: brand[800],
            }),
          }),
        },
        {
          props: { variant: 'soft', color: 'info' },
          style: ({ theme }) => ({
            color: '#12467b',
            backgroundColor: '#e2effb',
            ...theme.applyStyles('dark', {
              color: '#c7dff7',
              backgroundColor: '#0b2744',
            }),
          }),
        },
        {
          props: { variant: 'soft', color: 'warning' },
          style: ({ theme }) => ({
            color: '#5f4224',
            backgroundColor: '#fdefe1',
            ...theme.applyStyles('dark', {
              color: '#e3c9ab',
              backgroundColor: '#2e1b05',
            }),
          }),
        },
        {
          props: { variant: 'soft', color: 'error' },
          style: ({ theme }) => ({
            color: '#7e1717',
            backgroundColor: '#fce4e3',
            ...theme.applyStyles('dark', {
              color: '#f4c2c2',
              backgroundColor: '#430a0b',
            }),
          }),
        },
        {
          props: { variant: 'soft', color: 'success' },
          style: ({ theme }) => ({
            color: '#235721',
            backgroundColor: '#e3fbe3',
            ...theme.applyStyles('dark', {
              color: '#c7f7c7',
              backgroundColor: '#052f04',
            }),
          }),
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: ({ theme }) => ({
            backgroundColor: brownLight[90],
            '&:hover': {
              backgroundColor: brownLight[100],
            },
            '&:active': {
              filter: 'contrast(0.82) saturate(1.2) brightness(1.16)',
              backgroundColor: brownLight[100],
            },
            '&:disabled': {
              color: grayLight[80],
              backgroundColor: grayLight[30],
            },
            ...theme.applyStyles('dark', {
              color: 'rgb(217, 194, 179)',
              backgroundColor: '#574236ff',
              '&:hover': {
                backgroundColor: 'rgb(100, 85, 75)',
              },
              /*
              '&:active': {
                backgroundColor: brownDark[100],
              },
              '&:disabled': {
                color: grayDark[80],
                backgroundColor: grayDark[30],
              },
              */
            }),
          }),
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: ({ theme }) => ({
            color: brownLight[110],
            borderColor: brownLight[80],
            '&:hover': {
              backgroundColor: brownLight[20],
            },
            '&:active': {
              backgroundColor: brownLight[30],
            },
            '&:disabled': {
              color: grayLight[80],
              backgroundColor: grayLight[30],
            },
            ...theme.applyStyles('dark', {
              color: brownDark[110],
              borderColor: brownDark[80],
              '&:hover': {
                backgroundColor: brownDark[20],
              },
              '&:active': {
                backgroundColor: brownDark[30],
              },
              '&:disabled': {
                color: grayDark[80],
                backgroundColor: grayDark[30],
              },
            }),
          }),
        },
        {
          props: { variant: 'soft', color: 'primary' },
          style: ({ theme }) => ({
            color: brownLight[110],
            backgroundColor: brownLight[30],
            '&:hover': {
              backgroundColor: brownLight[40],
            },
            '&:active': {
              backgroundColor: brownLight[50],
            },
            '&:disabled': {
              color: grayLight[80],
              backgroundColor: grayLight[30],
            },
            ...theme.applyStyles('dark', {
              color: brownDark[110],
              backgroundColor: brownDark[30],
              '&:hover': {
                backgroundColor: brownDark[40],
              },
              '&:active': {
                backgroundColor: brownDark[50],
              },
              '&:disabled': {
                color: grayDark[80],
                backgroundColor: grayDark[30],
              },
            }),
          }),
        },
        {
          props: { variant: 'soft', color: 'secondary' },
          style: ({ theme }) => ({
            color: orangeLight[90],
            backgroundColor: orangeLight[30],
            '&:hover': {
              backgroundColor: orangeLight[40],
            },
            '&:active': {
              backgroundColor: orangeLight[50],
            },
            '&:disabled': {
              color: grayLight[80],
              backgroundColor: grayLight[30],
            },
            ...theme.applyStyles('dark', {
              color: orangeDark[90],
              backgroundColor: orangeDark[30],
              '&:hover': {
                backgroundColor: orangeDark[40],
              },
              '&:active': {
                backgroundColor: orangeDark[50],
              },
              '&:disabled': {
                color: grayDark[80],
                backgroundColor: orangeDark[30],
              },
            }),
          }),
        },
        {
          props: { variant: 'soft', color: 'error' },
          style: ({ theme }) => ({
            color: redLight[110],
            backgroundColor: redLight[30],
            '&:hover': {
              backgroundColor: redLight[40],
            },
            '&:active': {
              backgroundColor: redLight[50],
            },
            '&:disabled': {
              color: grayLight[80],
              backgroundColor: grayLight[30],
            },
            ...theme.applyStyles('dark', {
              color: redDark[110],
              backgroundColor: redDark[30],
              '&:hover': {
                backgroundColor: redDark[40],
              },
              '&:active': {
                backgroundColor: redDark[50],
              },
              '&:disabled': {
                color: grayDark[80],
                backgroundColor: grayDark[30],
              },
            }),
          }),
        },
      ],
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 6,
          //  textTransform: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.applyStyles('dark', {
            // backgroundImage: 'none',
          }),
        }),
      },
      variants: [
        {
          props: { variant: 'soft' },
          style: ({ theme }) => ({
            backgroundColor: grayLight[30],
            ...theme.applyStyles('dark', {
              backgroundColor: grayDark[30],
            }),
          }),
        },
        {
          props: { variant: 'soft', color: 'primary' },
          style: ({ theme }) => ({
            backgroundColor: brownLight[30],
            ...theme.applyStyles('dark', {
              backgroundColor: brownDark[30],
            }),
          }),
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: ({ theme }) => ({
            borderColor: brownLight[80],
            ...theme.applyStyles('dark', {
              borderColor: brownDark[80],
            }),
          }),
        },
        {
          props: { variant: 'surface' },
          style: ({ theme }) => ({
            border: '1px solid',
            borderColor: grayLight[60],
            backgroundColor: grayLight[20],
            ...theme.applyStyles('dark', {
              borderColor: grayDark[60],
              backgroundColor: grayDark[20],
            }),
          }),
        },
        {
          props: { variant: 'surface', color: 'primary' },
          style: ({ theme }) => ({
            border: '1px solid',
            borderColor: brownLight[80],
            backgroundColor: brownLight[20],
            ...theme.applyStyles('dark', {
              borderColor: brownDark[80],
              backgroundColor: brownDark[20],
            }),
          }),
        },
      ],
    },
    MuiOutlinedInput: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: grayLight[10],
          ...theme.applyStyles('dark', {
            backgroundColor: 'rgb(50, 45, 42)',
          }),
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '[data-mui-color-scheme="light"]': {
            '--form-bg-color': '#fafafa',
            '--form-text-color': '#000',
          },
          '[data-mui-color-scheme="dark"]': {
            '--form-bg-color': 'rgb(66, 66, 66)',
            '--form-text-color': '#fff',
          },
        },
        '#__next': {
          display: 'flex',
          minHeight: '100vh',
          flexDirection: 'column',
        },
        'div::-webkit-scrollbar': {
          width: 6,
        },
        'div::-webkit-scrollbar-thumb': {
          backgroundColor: brand[500],
          borderRadius: 4,
        },
      },
    },
  },
});

export const theme = responsiveFontSizes(pegaseTheme);
